
import { defineComponent, ref, onMounted, onUpdated, reactive } from "vue";

import ApiService from "@/core/services/ApiService";
import store from "@/store";

export default defineComponent({
  name: "rankmodepage",
  components: {
    //Datatable,
  },
  data() {
    return {
      rankItems: "",
      currentPage: Number(this.$route.params.page),
      currentMode: this.$route.params.mode,
      maxPage: 1,
      allRank: true
    };
  },
  created: function () {
    this.getData(this.$route.params.mode, this.$route.params.page);
  },
  mounted() {
    if (!this.$route.params.page) {
      this.$router.push({
        name: "rankmodepage",
        params: { mode: this.$route.params.mode, page: 1 },
      });
    }
  },
  watch: {
    currentPage() {
      this.getData(this.$route.params.mode, this.currentPage);
      this.$router.push({
        name: "rankmodepage",
        params: { mode: this.$route.params.mode, page: this.currentPage },
      });
      //this.currentPage = this.$route.params.page
    },
    currentMode() {
      this.getData(this.$route.params.mode, this.currentPage);
    },
    $route(to, from) {
      if (to.name == "rankmodepage") {
        this.currentPage = Number(this.$route.params.page);
        this.currentMode = this.$route.params.mode;
      }
    },
  },
  methods: {
    imageLoadError(e) {
      e.target.src = "https://php.rankopen.com/uc_server/data/avatar/image/default.png?version=1";
      //this.profileUrl = "https://php.rankopen.com/uc_server/data/avatar/image/default.png?version=1";
    },
    getData(type, page) {
      ApiService.post(
        process.env.VUE_APP_API_URL +
          "&mode=pub&tab=rank&type=" +
          type +
          "&page=" +
          page + "&allRank=" + this.allRank,
        {}
      ).then((response) => {
        this.rankItems = response.data.result;
        this.maxPage = response.data.maxPage;
      });
    },
  },
});
